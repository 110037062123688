import type { RelatedHeadlineResponse } from '@on3/api';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './RelatedArticle.module.scss';

export const RelatedArticle = ({
  article,
}: {
  article: RelatedHeadlineResponse;
}) => {
  const {
    fullUrl,
    featuredImage,
    primaryCategory,
    title,
    author,
    postDate,
    excerpt,
  } = article;
  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <article className={styles.articleContainer}>
      {featuredImage && (
        <TextLink
          className={styles.articleImageLink}
          href={fullUrl || '/'}
          title={title || 'Article Title'}
        >
          <ResizedImage
            className={styles.articleImage}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.5"
            height={215}
            quality={90}
            width={393}
          />
        </TextLink>
      )}
      <div className={styles.articleItemContainer}>
        <TextLink
          className={styles.category}
          color="primary"
          href={`${baseUrl}/news/`}
          underline="none"
          variant="subtitle1"
        >
          {primaryCategory?.name}
        </TextLink>
        <TextLink href={fullUrl || '/'} title={title || 'Article title'}>
          <Text className={styles.heading} component="h2">
            {title}
          </Text>
        </TextLink>
        <div className={styles.articleAuthorContainer}>
          <TextLink
            className={styles.author}
            href={`/user/${author?.slug}/`}
            variant="caption"
          >
            {author?.displayName}
          </TextLink>
          <span>&#8226;</span>
          <Text className={styles.date} variant="caption">
            {toFriendlyDate(postDate)}
          </Text>
        </div>
        <Text className={styles.articleBody} variant="body1">
          {excerpt}...
        </Text>
      </div>
    </article>
  );
};
