import { Button } from '@mui/material';
import { On3IconReadInApp } from '@on3/icons';
import { Email } from 'components/Svg/Email';
import { Facebook } from 'components/Svg/Facebook';
import { Twitter } from 'components/Svg/Twitter';
import React, { memo, useCallback } from 'react';

import styles from './ShareIcons.module.scss';

interface IShareIconsProps {
  title?: string | null;
  fullUrl?: string | null;
  primaryCategoryKey?: number | null;
  slug?: string | null;
}
// dimensions and options for the share window that pops up on click
const width = 575;
const height = 400;
const left = width / 2;
const top = height / 2;
const opts = `status=1,width=${width},height=${height},top=${top},left=${left}`;

export const ShareIcons = memo(function ShareIcons({
  title,
  fullUrl,
  primaryCategoryKey,
  slug,
}: IShareIconsProps) {
  const handleClick: React.MouseEventHandler = useCallback((e) => {
    const url = e.currentTarget.getAttribute('data-url');

    if (url) {
      window.open(url, '', opts);
    } else {
      console.warn(
        'No title provided to the `<ShareIcons>` component instance',
      );
    }

    return false;
  }, []);
  const encodedTitle = encodeURIComponent(title || '');
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=https://www.on3.com${fullUrl}`;
  const twitterUrl = `https://twitter.com/share?via=on3sports&text=${encodedTitle}&url=https://www.on3.com${fullUrl}`;
  const mailUrl = `mailto:?subject=${encodedTitle}&body=I found this article on www.on3.com. https://www.on3.com${fullUrl}`;

  const handleAppClick: React.MouseEventHandler = useCallback(
    (e) => {
      window.location.href = `on3://article/${primaryCategoryKey}/${slug}/`;

      const redirect = setTimeout(function () {
        window.location.href = 'https://www.on3.com/apps/on3/';
      }, 3000);

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          clearTimeout(redirect);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    },
    [primaryCategoryKey, slug],
  );

  return (
    <div className={styles.container} data-testid="shareIconContainer">
      <div className={styles.iconContainer}>
        <Button
          className={styles.iconBorder}
          data-testid="shareIcon-facebook"
          data-url={facebookUrl}
          onClick={handleClick}
        >
          <Facebook className={styles.icon} />
        </Button>
        <Button
          className={styles.iconBorder}
          data-testid="shareIcon-twitter"
          data-url={twitterUrl}
          onClick={handleClick}
        >
          <Twitter className={styles.icon} />
        </Button>
        <Button className={styles.iconBorder} href={mailUrl}>
          <Email className={styles.icon} data-testid="shareIcon-email" />
        </Button>
      </div>

      <Button className={styles.readInAppButton} onClick={handleAppClick}>
        <span className={styles.readInApp}>
          <On3IconReadInApp />
          Read In App
        </span>
      </Button>
    </div>
  );
});
