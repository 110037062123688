import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';

import styles from './PianoBarrier.module.scss';

export const PianoBarrier = () => {
  const { user } = useAuth();

  if (user?.has || user?.rg?.includes('administrator')) {
    return null;
  }

  return <div className={styles.barrier} id="pianoBarrier" />;
};
