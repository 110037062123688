import { useArticle } from '@on3/ui-lib/index';
import parse from 'html-react-parser';
import Head from 'next/head';

export const ArticleMeta = () => {
  const { article } = useArticle();
  const { author, head } = article || {};
  const publishTime = article?.postDate
    ? new Date(article?.postDate).toISOString()
    : null;
  const parsedHead = head ? parse(head) : '';

  return (
    <Head>
      {parsedHead}

      {article?.title && (
        <meta content={article?.title} property="sailthru.title" />
      )}
      {article?.tags && (
        <meta
          content={article?.tags?.map((tag) => tag.slug).join(',')}
          property="sailthru.tags"
        />
      )}

      {author?.name && (
        <>
          <meta content={author?.name} property="sailthru.author" />
          <meta content={author?.name} property="og:article:author" />
          <meta content={author?.name} property="cXenseParse:author" />
        </>
      )}
      {/* TODO: move these to page wrapper once spiny work is merged in */}
      <meta content="article" name="cXenseParse:pageclass" />
      {publishTime && (
        <>
          <meta content={publishTime} name="cXenseParse:publishtime" />
          <meta content={publishTime} name="sailthru.date" />
        </>
      )}
    </Head>
  );
};
