export function isAboveBottomOfScreen(elem: HTMLElement, pct = 0.4) {
  const top = elem.getBoundingClientRect().top;
  const screenHeight = window.innerHeight;
  const amountVisible = screenHeight - screenHeight * pct; // % of screen

  // top is 40% in screen
  return top < amountVisible;
}

export function isBelowBottomOfScreen(elem: HTMLElement) {
  const top = elem.getBoundingClientRect().top;
  const screenHeight = window.innerHeight;

  //top is below bottom of screen
  return top > screenHeight;
}
