import { SVGProps } from 'react';

export const On3HeaderGrey = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 55 24"
      width="55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16.4198 15.9681C16.1367 16.8765 15.7121 17.6414 15.0987 18.263C14.4853 18.8845 13.7304 19.4104 12.8339 19.745C11.8902 20.0797 10.805 20.2709 9.53104 20.2709H7.31342C6.03947 20.2709 4.95425 20.0797 4.01059 19.745C3.06692 19.4104 2.31198 18.8845 1.74578 18.263C1.1324 17.6414 0.70775 16.8765 0.42465 15.9681C0.14155 15.0598 0 14.0558 0 12.9562V10.2789C0 9.13148 0.14155 8.12749 0.42465 7.21913C0.70775 6.31076 1.1324 5.54582 1.74578 4.8765C2.35917 4.25498 3.1141 3.72909 4.01059 3.39443C4.95425 3.05977 6.03947 2.86853 7.31342 2.86853H9.53104C10.805 2.86853 11.8902 3.05977 12.8339 3.39443C13.7775 3.72909 14.5325 4.25498 15.0987 4.8765C15.7121 5.49801 16.1367 6.31076 16.4198 7.21913C16.7029 8.12749 16.8445 9.13148 16.8445 10.2789V12.9562C16.8916 14.0558 16.7501 15.0598 16.4198 15.9681ZM3.77467 16.9243C4.43524 17.6892 5.52045 18.0717 7.03032 18.0717H9.90851C11.4184 18.0717 12.5036 17.6892 13.1642 16.9243C13.8247 16.1594 14.1078 15.0598 14.1078 13.5299V9.60957C14.1078 8.07969 13.7775 6.98008 13.1642 6.21514C12.5036 5.4502 11.4184 5.11554 9.90851 5.11554H7.03032C5.52045 5.11554 4.43524 5.49801 3.77467 6.21514C3.1141 6.98008 2.78382 8.07969 2.78382 9.60957V13.5299C2.78382 15.0598 3.1141 16.2072 3.77467 16.9243Z"
          fill="#A2A9AD"
        />
        <path
          d="M31.6127 20.6056C30.905 20.6056 30.3388 20.4143 29.8669 20.0319C29.4423 19.6494 29.0648 19.1713 28.6874 18.5976L22.9782 9.27489C22.8838 9.08366 22.7894 8.94023 22.6951 8.84461C22.6479 8.7968 22.6479 8.7968 22.6007 8.7968C22.5063 8.7968 22.4592 8.84461 22.4592 8.84461C22.412 8.89242 22.412 9.03585 22.412 9.17927V20.3665H19.8169V9.27489C19.8169 8.46214 20.0056 7.74501 20.4303 7.17131C20.8549 6.54979 21.5627 6.26294 22.5063 6.26294C23.1669 6.26294 23.7331 6.45417 24.2049 6.88445C24.6296 7.26692 25.0542 7.79282 25.4789 8.50995L31.0465 17.6892C31.1409 17.8327 31.2353 17.9761 31.3296 18.0239C31.3768 18.0717 31.424 18.1195 31.4712 18.1195C31.5655 18.1195 31.5655 18.0717 31.6127 18.0717C31.6599 18.0239 31.6599 17.9283 31.6599 17.8327V6.50198H34.2078V17.737C34.2078 18.5976 33.9719 19.2669 33.5472 19.7928C33.1226 20.3187 32.462 20.6056 31.6127 20.6056Z"
          fill="#A2A9AD"
        />
        <path
          d="M49.6841 24H42.0404C39.4925 24 37.4636 21.8964 37.4636 19.3625V4.63745C37.4636 2.05578 39.5397 0 42.0404 0H49.6369C52.1848 0 54.2137 2.10359 54.2137 4.63745V19.3147C54.2609 21.8964 52.1848 24 49.6841 24Z"
          fill="#A2A9AD"
        />
        <path
          d="M46.334 20.6056C46.1924 20.6056 46.0981 20.6056 45.9565 20.6056H40.7664V17.1634H46.4755C47.0417 17.0678 47.4664 16.6853 47.5608 16.2072C47.5608 16.1116 47.6079 16.016 47.6079 15.9682C47.6079 14.6295 45.2016 13.7211 44.2107 12.8128V11.2829C45.2016 10.3745 47.6079 9.37055 47.6079 8.12752C47.6079 8.0319 47.6079 7.93629 47.5608 7.88848C47.4664 7.41039 47.0417 6.98011 46.5227 6.9323H40.7664V3.44226H45.9093C46.0509 3.44226 46.1453 3.44226 46.2868 3.44226C46.4284 3.44226 46.5227 3.44226 46.6171 3.44226C48.646 3.58569 50.3918 5.06776 50.7692 7.02792C50.8164 7.31477 50.8636 7.60162 50.8636 7.93629C50.8636 9.46617 50.1087 10.8526 48.8347 11.6654L48.4101 12L48.8347 12.3347C50.1087 13.1952 50.8636 14.5817 50.8636 16.0638C50.8636 16.3506 50.8164 16.6853 50.7692 16.9721C50.3446 18.9323 48.646 20.4144 46.6171 20.5578C46.5699 20.6056 46.4284 20.6056 46.334 20.6056Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="24" width="54.2609" />
        </clipPath>
      </defs>
    </svg>
  );
};
