import type { RelatedHeadlineResponse } from '@on3/api';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { RelatedArticle } from 'components/Headlines/RelatedArticles/RelatedArticle';
import { Text } from 'components/UI/Typography/Text';
import React from 'react';

import styles from './RelatedArticlesList.module.scss';

export const RelatedArticlesList = ({
  list,
}: {
  list: RelatedHeadlineResponse[];
}) => {
  if (!list || list?.length < 1) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Text className={styles.heading} component="h3">
        You may also like
      </Text>
      <div className={styles.articles}>
        {list?.slice(0, 25).map((article, index) => {
          return (
            <React.Fragment key={article.key}>
              <RelatedArticle article={article} />
              {(index + 1) % 5 === 0 && index > 0 && (
                <MobileMPU
                  id={`mobile_mpu_bottom-${(index + 1) / 5}`}
                  incontent
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
