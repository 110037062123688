import type { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import {
  externalApi,
  internalApi,
  proxyApi,
  proxyApiWithVary,
} from '@on3/ui-lib/utils/api';
import Cookies from 'cookies';
import jwtDecode from 'jwt-decode';
import type { NextComponentType, NextPageContext } from 'next';

import { userAccessHeader } from '../contexts/AuthProvider';
import { NextApiParams } from '../types/next';

const withAuthentication = <P extends object>(
  WrappedComponent: NextComponentType<NextPageContext, unknown, P>,
) =>
  function WithAuthentication(props: P) {
    return <WrappedComponent {...props} />;
  };

withAuthentication.isAuth = async ({
  req,
  res,
}: NextApiParams): Promise<IUser | undefined | null> => {
  const cookies = new Cookies(req, res);

  try {
    const token = cookies.get('token');

    const refreshToken = cookies.get('refreshToken');
    // This needs to be done when requests are made
    // const expired =
    //   new Date(parseInt(jwtDecode(cookies.token).exp)).getTime() >
    //   new Date().getTime();

    if (!token) {
      if (refreshToken) {
        const { data } = await externalApi.post('users/v1/users/refresh/', {
          refreshToken,
        });

        const {
          token: newToken,
          refreshBefore: newRefreshBefore,
          refreshToken: newRefreshToken,
        } = data;
        const expires = new Date(newRefreshBefore);
        const week = new Date();

        week.setDate(week.getDate() + 7);
        if (newToken) {
          cookies.set('token', newToken, {
            expires,
            path: '/',
            httpOnly: false,
          });
          cookies.set('refreshToken', newRefreshToken, {
            expires: week,
            path: '/',
            httpOnly: false,
          });
          const decodedUserToken: IUser = {
            ...jwtDecode(newToken),
            jwt: newToken,
          };
          const userAccessHeaderValue = userAccessHeader(decodedUserToken);

          internalApi.defaults.headers.Authorization = `Bearer ${newToken}`;
          externalApi.defaults.headers.Authorization = `Bearer ${newToken}`;
          proxyApi.defaults.headers.Authorization = `Bearer ${newToken}`;
          proxyApiWithVary.defaults.headers.Authorization = `Bearer ${newToken}`;
          proxyApiWithVary.defaults.headers.AccessLevel = userAccessHeaderValue;

          return decodedUserToken;
        }

        delete internalApi.defaults.headers.Authorization;
        delete externalApi.defaults.headers.Authorization;
        delete proxyApi.defaults.headers.Authorization;
        delete proxyApiWithVary.defaults.headers.Authorization;
        delete proxyApiWithVary.defaults.headers.AccessLevel;
      } else {
        delete internalApi.defaults.headers.Authorization;
        delete externalApi.defaults.headers.Authorization;
        delete proxyApi.defaults.headers.Authorization;
        delete proxyApiWithVary.defaults.headers.Authorization;
        delete proxyApiWithVary.defaults.headers.AccessLevel;
      }
    } else {
      const user: IUser = { ...jwtDecode(token), jwt: token };
      const userAccessHeaderValue = userAccessHeader(user);

      internalApi.defaults.headers.Authorization = `Bearer ${token}`;
      externalApi.defaults.headers.Authorization = `Bearer ${token}`;
      proxyApi.defaults.headers.Authorization = `Bearer ${token}`;
      proxyApiWithVary.defaults.headers.Authorization = `Bearer ${token}`;
      proxyApiWithVary.defaults.headers.AccessLevel = userAccessHeaderValue;

      return user;
    }

    return null;
  } catch (error) {
    console.error('withAuthentication Error: ', error);

    cookies.set('refreshToken', '', {
      expires: new Date(`Thu, 01 Jan 1970 00:00:01 GMT`),
      path: '/',
      httpOnly: false,
    });

    delete internalApi.defaults.headers.Authorization;
    delete externalApi.defaults.headers.Authorization;
    delete proxyApi.defaults.headers.Authorization;
    delete proxyApiWithVary.defaults.headers.Authorization;
    delete proxyApiWithVary.defaults.headers.AccessLevel;

    return null;
  }
};

export default withAuthentication;
export { withAuthentication };
